import "./formFile.scss";
import propTypes from "prop-types";
import { VscCloudUpload } from "react-icons/vsc";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { deleteFile, uploadFile } from "services/files.service";
import toast from "react-hot-toast";

const defaultFileType = ["png", "jpg", "jpeg"];

function FormFile({
  label,
  value,
  onChange,
  fileText = "Upload  an image or drag & drop image here",
  fileMax = `Maximum file size of 10 MB (${defaultFileType.toString()})`,
  // isRequired,
  // allowedFiles,
  size = "10485760",
}) {
  const [filePrev, setFilePrev] = useState(null);
  const [fileId, setFileId] = useState("");
  const [editMode, setEditMode] = useState(value ? false : true);
  const [validationMessage, setValidationMessage] = useState("");
  const fileRef = useRef(null);

  useEffect(() => {
    if (value) {
      setEditMode(false);
    }
  }, [value]);

  const { isLoading, mutate: uploadFileMutate } = useMutation(
    async (formData) => await uploadFile(formData),
    {
      onSuccess(data) {
        const uploadedFile = {
          name: data.data?.fileName,
          size: data?.data?.size,
          url: data.data.url,
          id: data.data.id,
          type: data?.data?.type,
        };
        onChange(uploadedFile);
        setFileId(data?.data?.id);
        toast.success(data.message);
        setEditMode(false);
      },

      onError(error) {
        toast.error(error?.response?.data?.message);
        setValidationMessage(error?.response?.data?.message);
      },
    }
  );

  const { mutate: deleteFileMutate } = useMutation(
    async (fileId) => await deleteFile(fileId),
    {
      onSuccess(data) {
        toast.success(data.message);
      },
      onError(error) {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const setToDefalt = () => {
    fileRef?.current?.click();

    if (fileId) deleteFileMutate(fileId);

    if (value) deleteFileMutate(value.id);
    setEditMode(true);

    if (filePrev) {
      URL.revokeObjectURL(filePrev);
    }
  };

  useEffect(() => {
    return () => {
      if (filePrev) URL.revokeObjectURL(filePrev);
    };
  }, [filePrev]);

  const handleChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (isValidFileSize(file.size)) {
      setFilePrev(URL.createObjectURL(file));

      const formData = new FormData();
      formData.append("file", file);
      uploadFileMutate(formData);
    }
  };

  // max 10mb
  const isValidFileSize = (fileSize) => {
    if (fileSize > Number(size)) {
      setValidationMessage("File size must be 10mb max");
      return false;
    }
    setValidationMessage("");
    return true;
  };

  // allowed file type: ["pdf", "txt", "docx", "xlsx"]
  // const isValidFileType = (fileType) => {
  //   const allowedExtensions = allowedFiles ? [allowedFiles] : defaultFileType;
  //   const fileExtension = fileType.split("/").pop();

  //   if (!allowedExtensions.includes(fileExtension)) {
  //     setValidationMessage(
  //       `required file type is any of: ${allowedExtensions.toLocaleString()}`
  //     );

  //     return false;
  //   }
  //   setValidationMessage("");
  //   return true;
  // };
  return (
    <div className="imgfile-container">
      {(!editMode && filePrev) || value?.url ? (
        <>
          {filePrev ? (
            <img src={filePrev} alt="#" />
          ) : (
            <img src={value.url} alt="#" />
          )}
        </>
      ) : (
        <label for="inputTag" className="form-file-holder">
          <span className="icon-tree">
            <VscCloudUpload />
          </span>
          <span>{fileText} </span>
          <span>{fileMax}</span>
          <span className="label">{label} </span>
          {isLoading && <span className="label">Uploading File... </span>}
        </label>
      )}
      {!editMode && (filePrev || value?.url) && (
        <div className="change-img-holder">
          <button type="button" disabled={isLoading} onClick={setToDefalt}>
            {" "}
            {isLoading ? "Uploading file..." : "Change File"}
          </button>
        </div>
      )}
      <input
        ref={fileRef}
        id="inputTag"
        type="file"
        accept="image/*"
        onChange={handleChange}
        className="hidden-file"
      />
      {/* <input
        required={value ? false : isRequired}
        autoFocus={false}
        style={{ visibility: "hidden", height: "0px" }}
      /> */}
      <label
        className={`label error-msg ${
          validationMessage && "error-msg-visible"
        }`}
      >
        {validationMessage}
      </label>
    </div>
  );
}
FormFile.propTypes = {
  classname: propTypes.string,
  inputClassName: propTypes.string,
  label: propTypes.string,
};
export default FormFile;
