import React from "react";
import "./round-user-avatar.scss";
import Image from "components/image/index";
import { UserAvatar } from "assets/index";

const RoundUserAvatar = ({ imgSrc }) => {
  return (
    <div className="round-user-avatar">
      <Image
        src={imgSrc}
        alt="this is a user avatar"
        className='"round-user-avatar__img'
        fallbackSrc={UserAvatar}
      />
    </div>
  );
};

export default RoundUserAvatar;
