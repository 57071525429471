import { useEffect } from "react";

const useDisableBodyScroll = (isScrollable) => {
  useEffect(() => {
    if (isScrollable) {
      document.body.style.overflow = "scroll";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isScrollable]);
};

export default useDisableBodyScroll;
