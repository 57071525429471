import {
  handleDeleteRequest,
  handleGetRequest,
  handlePostRequest,
  handlePuttRequest,
} from "./httpClient";

export const addPrize = async (input) => {
  return await handlePostRequest(`/prizes`, input);
};

export const getPrizes = async () => {
  return await handleGetRequest(`/prizes`);
};

export const editPrize = async (id, input) => {
  return await handlePuttRequest(`/prizes/${id}`, input);
};
export const deletePrize = async (id) => {
  return await handleDeleteRequest(`/prizes/${id}`);
};

export const getOnePrize = async (id) => {
  return await handleGetRequest(`/prizes/${id}`);
};
