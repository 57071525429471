//admin route definitions
export const ADMIN_CONTENTMANGEMENT_INDEX_ROUTE = "/content-management/admin";
export const ADMIN_DASHBOARD_ROUTE = "dashboard";
export const ADMIN_ADD_ADMIN_ROUTE = "add-admin";
export const ADMIN_VIEW_ADMIN_DETAILS_ROUTE = "admins/:id";
export const ADMIN_VIEW_ALL_CONTEST_ROUTE = "contests";
export const ADMIN_VIEW_CONTESTS_ROUTE = "view-contests/:id";
export const ADMIN_VIEW_CONTESTS__CREATE_PHASE_ROUTE =
  "/contests/:id/create-phase";
export const ADMIN_EDIT_CONTEST_ROUTE = "edit-contests/:id";
export const ADMIN_CREATE_CONTEST_ROUTE = "create-contest";
export const ADMIN_VIEW_ALL_BROADCAST_ROUTE = "broadcasts";
export const ADMIN_CREATE_BROADCAST_ROUTE = "create-broadcast";
export const ADMIN_BROADCAST_CARDUPDATE_ROUTE = "broadcast-cardupdate";
export const ADMIN_BROADCAST_PUBLISHED_ROUTE = "published/:id";
export const ADMIN_BROADCAST_ARCHIVE_ROUTE = "archive/:id";
export const ADMIN_BROADCAST_EDIT_ARCHIVE_ROUTE = "edit-archive/:id";
export const ADMIN_BROADCAST_DRAFT_ROUTE = "draft/:id";
export const ADMIN_VIEW_ALL_CONTESTANTS_ROUTE = "contestants";
export const ADMIN_VIEW_CONTESTANTS_DETAILS_ROUTE = "contestants/:id";
export const ADMIN_VIEW_CONTESTANTS_CONTEST_ROUTE = "users/:id/contests";
export const ADMIN_VIEW_ALL_CONTEST_PAYMENTS_ROUTE = "payments";
export const ADMIN_VIEW_ALL_CONTEST_PAYMENTS_DETAILS_ROUTE = "payments/:id";
export const ADMIN_VIEW_PHASE_RESULTS_ROUTE = "results";
export const ADMIN_VIEW_RESULTS_PHASE_ROUTE = "contests/:id/results";
export const ADMIN_CONTEST_PHASES__ROUTE = "contests/:id/phases";
export const ADMIN_VIEW_CONTEST_PHASE__ROUTE = "phases/:id";
export const ADMIN_VIEW_CONTEST_PHASE__DETAILS_ROUTE = "phase-submission/:id";
export const ADMIN_EDIT_PHASE__ROUTE = "/phases/:id/edit-phase";
export const ADMIN_VIEW_PHASE_VOTE_ROUTE = "contests/:contest/phases/:id/votes";
export const ADMIN_VIEW_ALL_CONTEST_VIDEO_ROUTE = "videos";
export const ADMIN_VIEW_ALL_CONTEST_VIDEOPHASE_ROUTE = "contests/:id/videos";
export const ADMIN_VIEW_ALL_VIDEOS_ROUTE = "phases/:id/videos";
export const ADMIN_SETUP_CONTEST_ROUTE = "setup";
export const ADMIN_CREATE_SETUP_CONTEST_ROUTE = "create-prize";
export const ADMIN_CREATE_TALENT_CONTEST_ROUTE = "create-new-talent";
export const ADMIN_CREATE_TEAM_CONTEST_ROUTE = "create-new-team";
export const ADMIN_EDIT_SETUP_CONTEST_ROUTE = "teams/:id";
export const ADMIN_EDIT_PRICE_CONTEST_ROUTE = "prizes/:id/edit";
export const ADMIN_EDIT_TALENT_CATEGORY_CONTEST_ROUTE = "talent-categories/:id";
export const ADMIN_ROLES_PERMISSION_CONTEST_ROUTE = "rolespermission";
export const ADMIN_EDIT_ROLES_CONTEST_ROUTE = "roles/:id/admins";
export const ADMIN_VIEW_ALL_VOTERS_ROUTE = "voters";
export const VIEW_ALL_ADMIN_USERS_ROUTE = "users";
export const NOTIFICATION_INDEX_ROUTE = "notifications";
export const PROFILE_INDEX_ROUTE = "profile";
export const VOTER_PROFILE_INDEX_ROUTE = "voter-profile";
export const CONTESTANTS_APPLICATIONS_ROUTE = "applications/:id";
