import propTypes from "prop-types";
import "./textInput.scss";

const TextInput = ({
  label,
  placeholder,
  className = "text-input",
  errorMessage,
  type = "text",
  ...rest
}) => {
  return (
    <div className="textInput-container">
      {label && <label className="label">{label}</label>}

      <input
        type={type}
        placeholder={placeholder}
        className={className}
        {...rest}
      />
      {errorMessage && <i className="error-message">{errorMessage}</i>}
    </div>
  );
};
TextInput.propTypes = {
  label: propTypes.string,
  placeholder: propTypes.string,
  className: propTypes.string,
  type: propTypes.string,
};
export default TextInput;
