import FormFile from "components/formFile/FormFile";
import React, { useState } from "react";
import "./setupTermsAndConditions.scss";
import useTerms from "hooks/useTerm";
import Skeleton from "react-loading-skeleton";
import useInputChange from "hooks/useInputChange";
import Button from "components/button/index";
import { useMutation } from "react-query";
import { updateTerms } from "services/terms.service";
import toast from "react-hot-toast";

const SetupTermsAndConditions = () => {
  const { isLoading, data } = useTerms();
  const [showUpdate, setShowUpdate] = useState(false);

  const { state, onChangeByNameValue } = useInputChange({
    file: "",
  });

  const { mutate } = useMutation(async (input) => await updateTerms(input), {
    onSuccess(data) {
      toast.success(data?.message);
    },
    onError(error) {
      toast.error(error?.response?.data?.message);
    },
  });

  const toggleUpdate = () => {
    setShowUpdate(!showUpdate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(state);
  };
  return (
    <div className="setupTerms-container">
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          {!data?.data ? (
            <>
              <div>
                <p>Update terms of service...</p>
              </div>
              <div>
                <form className="upload-file" onSubmit={handleSubmit}>
                  <FormFile
                    label=""
                    fileText="Upload file"
                    fileMax="Maximum file size of 10 MB (pdf, docx)"
                    allowedFiles={["pdf", "docx"]}
                    onChange={(file) => onChangeByNameValue("file", file)}
                    isRequired={true}
                  />
                  <Button type="submit" isLoading={isLoading}>
                    Update
                  </Button>
                </form>
              </div>
            </>
          ) : (
            <>
              {showUpdate ? (
                <form className="upload-file" onSubmit={handleSubmit}>
                  <FormFile
                    label=""
                    fileText="Upload file"
                    fileMax="Maximum file size of 10 MB (pdf, docx)"
                    allowedFiles={["pdf", "docx"]}
                    onChange={(file) => onChangeByNameValue("file", file)}
                    isRequired={true}
                  />
                  <Button type="submit" isLoading={isLoading}>
                    Update
                  </Button>
                </form>
              ) : (
                <div>
                  <div className="view__terms">
                    <a
                      href={data?.data?.file?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View terms and conditions...
                    </a>
                  </div>
                  <Button onClick={toggleUpdate}>
                    Update Terms of Severvice
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SetupTermsAndConditions;
