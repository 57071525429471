import React, { useState } from "react";
import "./search-input.scss";

const SearchInput = ({ placeholder, onSearch }) => {
  const [value, setValue] = useState("");

  const hamdleChange = (e) => {
    setValue(e.target.value);
    if (onSearch) onSearch(e.target.value);
  };
  return (
    <form className="search-input--form">
      <div className="form--control input-field--holder">
        <input
          type="search"
          value={value}
          onChange={hamdleChange}
          required
          placeholder={placeholder}
        />
      </div>
      {/* <div className="form--control">
        <button type="submit" className="button button__submit">
          <GoSearch />
        </button>
      </div> */}
    </form>
  );
};

export default SearchInput;
