import { handleGetRequest, handlePostRequest } from "services/httpClient";

export const createBroadcast = async (input) => {
  return await handlePostRequest("/broadcast", input);
};

export const getBroadcasts = async (params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(`/broadcast${queryString}`);
};

const conststructQuery = (params) => {
  const { page = 1, limit = 50 } = params;
  let query = `?page=${page}&limit=${limit}`;

  if (params.search) {
    query = query.concat(`&search=${params.search}`);
  }
  if (params.type) {
    query = query.concat(`&type=${params.type}`);
  }
  if (params.status) {
    query = query.concat(`&status=${params.status}`);
  }

  return query;
};
