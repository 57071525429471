import { useState } from "react";
import { useQuery } from "react-query";
import useDebounce from "./useDebounceSearch";
import { getBroadcasts } from "pages/content-management/admin/broadcasts/broadcast.service";

const useBroadcast = (defaultQuery) => {
  const [query, setQuery] = useState(
    defaultQuery || {
      page: 1,
      limit: 20,
      search: "",
      type: "",
      status: "",
    }
  );
  const debounceSearch = useDebounce(query.search, 3000);

  const { page, status, type } = query;
  const { isLoading, data, refetch } = useQuery(
    ["broadcasts", page, debounceSearch, status, type],
    async () => await getBroadcasts(query)
  );

  const onQueryChange = (name, value) => {
    setQuery((q) => ({ ...q, [name]: value }));
  };

  const onFilter = (queryFilter) => {
    setQuery(queryFilter);
  };

  return { isLoading, data, onQueryChange, onFilter, refetch };
};

export default useBroadcast;
