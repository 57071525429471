import {
  handleDeleteRequest,
  handleGetRequest,
  handlePostRequest,
  handlePuttRequest,
} from "./httpClient";

export const addTalentCategory = async (input) => {
  return await handlePostRequest(`/talent-categories`, input);
};

export const getTelentCategories = async () => {
  return await handleGetRequest(`/talent-categories`);
};

export const editTalentCategories = async (id, input) => {
  return await handlePuttRequest(`/talent-categories/${id}`, input);
};
export const deleteTalentCategory = async (id) => {
  return await handleDeleteRequest(`/talent-categories/${id}`);
};

export const getOneTalentCategory = async (id) => {
  return await handleGetRequest(`/talent-categories/${id}`);
};
