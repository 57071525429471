import { useEffect, useState } from "react";
// import { AiFillCaretDown } from "react-icons/ai";
import Select from "react-select/async";
// import { components } from "react-select";
import propTypes from "prop-types";
import "./selectInput.scss";

const AsyncSelectInput = ({
  options = [],
  loadOptions,
  placeHolder = "Select",
  //selectCustomClass,
  labelText,
  labelCustomClass,
  name,
  value,
  handleChange,
  isRequired,
  disabled = false,
  errorLabel = "",
  multiple = false,
}) => {
  const [selectedOption, setSelectedOption] = useState(value || "");
  const [defaultValue, setDefaultValue] = useState(value);

  const changeHandler = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (multiple) {
      const options = selectedOption.map((option) => option.value);
      handleChange(name, options);
    } else handleChange(name, selectedOption.value);
  };

  useEffect(() => {
    let val = null;
    if (!multiple && value) {
      val = options.find((option) => option.value === value);
      setDefaultValue(val);
    } else if (multiple && value) {
      const selected = value.map((item) => {
        const theOption = options.find((option) => option.value === item);

        if (theOption) return theOption;
        return null;
      });
      setDefaultValue(selected);
    }
  }, [options, multiple, value]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#212121" : "#212121",
      backgroundColor: state.isSelected ? "#d3a937" : "#F5F5F5",
      textAlign: "left",
      fontSize: "0.75rem",
      fontWeight: "normal",
      border: "none",
    }),
    control: (provided) => ({
      ...provided,
      border: "none",
      backgroundColor: "rgba(42, 42, 42, 0.0470588235)",
      minHeight: "47px",
    }),
    placeHolder: {
      color: "#707070",
      fontWeight: "normal",
      fontSize: "0.75rem",
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        color: "#212121",
        fontSize: "0.95rem",
        marginLeft: "-0.15rem",
        fontWeight: "normal",
        backgroundColor: "transparent",
      };
    },
  };

  // const DropdownIndicator = (props) => {
  //   return (
  //     <components.DropdownIndicator {...props}>
  //       <div className="select-icon-wrapper">
  //         <AiFillCaretDown />
  //       </div>
  //     </components.DropdownIndicator>
  //   );
  // };
  return (
    <>
      <label
        className={`select-wraper-label ${
          disabled && "select-wraper-label-disabled"
        } ${labelCustomClass}`}
      >
        {labelText}
      </label>
      <div className="select-wraper">
        <Select
          key={defaultValue}
          defaultValue={defaultValue}
          onChange={changeHandler}
          cacheOptions
          defaultOptions={options}
          loadOptions={loadOptions}
          styles={customStyles}
          placeholder={placeHolder}
          isDisabled={disabled}
          isSearchable={true}
          // components={{ DropdownIndicator }}
          isMulti={multiple}
        />
        {isRequired && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{ opacity: 0, height: 1 }}
            value={selectedOption}
            required={isRequired}
            onChange={() => {}}
          />
        )}
        {/* <label
        className={`label error-msg ${innerTextError && "error-msg-visible"}`}
      >
        {innerTextError}
      </label> */}
        {errorLabel && <label className="error-label">{errorLabel}</label>}
      </div>
    </>
  );
};

export default AsyncSelectInput;
AsyncSelectInput.propTypes = {
  options: propTypes.array,
  loadOptions: propTypes.func.isRequired,
  placeHolder: propTypes.string,
  selectCustomClass: propTypes.string,
  labelText: propTypes.string,
  labelCustomClass: propTypes.string,
  value: propTypes.any,
  handleChange: propTypes.func,
  isRequired: propTypes.bool,
  disabled: propTypes.bool,
  name: propTypes.string,
  errorLabel: propTypes.string,
  multiple: propTypes.any,
};
