import Button from "components/button";
import Modal from "components/modal/Modal";
import React, { useState } from "react";
import { RiEditLine } from "react-icons/ri";
import * as ContentMgtURL from "constants/urls/ContentManagement";
import { RiDeleteBin5Line } from "react-icons/ri";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./prizes.scss";
import usePrizes from "hooks/usePrizes";
import Skeleton from "react-loading-skeleton";
import Jumbotron from "components/jumbotron/index";
import { useMutation, useQueryClient } from "react-query";
import { deletePrize } from "services/prize";

const Prizes = () => {
  const [openModal3, setOpenModal3] = useState(false);
  const { isLoading, data } = usePrizes();
  const [prize, setPrize] = useState(null);

  const queryClient = useQueryClient();

  const { isLoading: deleting, mutate: deletePrizeMutate } = useMutation(
    async (id) => await deletePrize(id),
    {
      onSuccess(data) {
        toast.success(data?.message);
        setOpenModal3(false);
        queryClient.invalidateQueries("prizes");
      },
      onError(error) {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const confirmDelete = () => {
    deletePrizeMutate(prize._id);
  };

  const navigator = useNavigate();
  const navigatToCreateNew = () => {
    navigator(
      `${ContentMgtURL.ADMIN_CONTENTMANGEMENT_INDEX_ROUTE}/create-prize`
    );
  };

  const navigatToEdit = (id) => {
    navigator(
      `${ContentMgtURL.ADMIN_CONTENTMANGEMENT_INDEX_ROUTE}/prizes/${id}/edit`
    );
  };

  const handleDelete = (prize) => {
    setPrize(prize);
    setOpenModal3(true);
  };

  return (
    <div className="resulttable__wrapper">
      <div className="add-btn-wrapper">
        <Button onClick={navigatToCreateNew}>+ Add New</Button>
      </div>

      {openModal3 && (
        <Modal
          showModal={openModal3}
          showModalHandler={() => setOpenModal3(false)}
        >
          <div className="view__modal__user1">
            <div>
              <h3 className="sure__del">Are you sure?</h3>
              <div className="btns2">
                <Button className="c__btn" onClick={() => setOpenModal3(false)}>
                  <span>Cancel</span>
                </Button>
                <Button
                  className="c__btn__1"
                  btnText="DELETE"
                  onClick={confirmDelete}
                  isLoading={deleting}
                >
                  <span>Delete</span>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div className="">
        <div>
          <table className="result__table__container3">
            <thead className="result__table__header">
              <tr>
                <th className="result__header__items">
                  <p>Contest</p>
                </th>
                <th className="result__header__items">
                  <p>Prize Name</p>
                </th>
                <th className="result__header__items">
                  <p>Winning Category</p>
                </th>
                <th className="result__header__items">
                  <p>Description</p>
                </th>
                <th className="result__header__items">
                  <p>Action</p>
                </th>
              </tr>
            </thead>
            <tbody className="result__data__items__holder">
              {isLoading ? (
                <td colSpan={5}>
                  <Skeleton count={3} />
                </td>
              ) : (
                <>
                  {data?.data.total < 1 ? (
                    <tr>
                      <td colSpan={5}>
                        <Jumbotron message="No Data" />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {data?.data?.data?.map((item) => (
                        <tr key={item._id}>
                          <td className="result__data__itemss">
                            {item?.contest?.title}
                          </td>
                          <td className="result__data__items">{item.name}</td>
                          <td className="result__data__items">
                            {item.category}
                          </td>
                          <td>{item.description || ""}</td>

                          <td className="result__data__items1">
                            <div className="del__edi__holder">
                              <p className="edi">
                                <RiEditLine
                                  onClick={() => navigatToEdit(item._id)}
                                />
                              </p>
                              <p className="dele">
                                <RiDeleteBin5Line
                                  onClick={() => handleDelete(item)}
                                />
                              </p>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Prizes;
