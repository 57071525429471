import DashBoardHeader from "components/headers/dashboard-header/DashBoardHeader";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BroadcastCard from "../BroadcastCard";
import * as ContentMgtURL from "constants/urls/ContentManagement";
// import "./broadCasts.scss";
import useBroadcast from "hooks/useBroadcast";
import { BroadcastStatus } from "constants/enums";
import Skeleton from "react-loading-skeleton";
import Jumbotron from "components/jumbotron/index";
import Pagination from "components/pagination/Pagination";
import SearchInput from "components/search-input/SearchInput";

const AllArchiveBroadCasts = ({ search }) => {
  const [query] = useState({
    type: "",
    search: search,
    status: BroadcastStatus.Archive,
  });

  const { isLoading, data, onQueryChange } = useBroadcast(query);

  const pathToDraft = useNavigate();

  const handleBroadcastView = (broadcast) => {
    pathToDraft(
      `${
        ContentMgtURL.ADMIN_CONTENTMANGEMENT_INDEX_ROUTE
      }/${BroadcastStatus.Archive.toLowerCase()}/${broadcast._id}`
    );
  };

  const handlePaginate = (page) => {
    onQueryChange("page", page);
  };
  return (
    <>
      <DashBoardHeader headerTitle="Broadcasts" />
      <div className="search-box">
        <SearchInput
          placeholder="Search"
          onSearch={(val) => onQueryChange("search", val)}
        />
      </div>
      <div>
        <div className="broadcast-wrapper">
          <div className="published-section">
            {isLoading ? (
              <>
                {[1, 2, 3, 4].map((el) => (
                  <Skeleton key={el} height={200} width={300} />
                ))}
              </>
            ) : (
              <>
                {data?.data?.total < 1 ? (
                  <Jumbotron
                    message={`No ${BroadcastStatus.Archive} broadcast`}
                  />
                ) : (
                  <>
                    {data?.data?.data?.map((broadcast) => (
                      <div className="broadcast" key={broadcast._id}>
                        <BroadcastCard
                          broadcast={broadcast}
                          onClick={handleBroadcastView}
                        />
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <Pagination
          handlePageClick={handlePaginate}
          currentPage={data?.data.page}
          limit={data?.data?.limit}
          total={data?.data?.total}
        />
      </div>
    </>
  );
};

export default AllArchiveBroadCasts;
