import React, { useEffect } from "react";
import Button from "components/button";
import toast from "react-hot-toast";
import "./editPrice.scss";
import TextInput from "components/inputs/TextInput";
import TextArea from "components/inputs/TextArea";
import DashBoardHeader from "components/headers/dashboard-header/DashBoardHeader";
import InlineNavigation from "components/inline-navigation/InlineNavigation";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { editPrize } from "services/prize";
import useContests from "hooks/useContests";
import AsyncSelectInput from "components/asyncSelectInput/index";
import { getContests } from "services/contest.service";
import SelectInput from "components/selectInput/index";
import useInputChange from "hooks/useInputChange";
import usePrize from "hooks/usePrize";

const EditPrice = () => {
  const { id } = useParams();
  const { isLoading: fetching, data } = usePrize(id);

  const { state, onChange, onChangeByNameValue, setState } = useInputChange({
    name: "",
    description: "",
    category: "",
    contest: "",
  });
  const { data: contests } = useContests();
  const navigation = useNavigate();

  useEffect(() => {
    if (data?.data) {
      const payload = {
        ...data.data,
      };
      setState(payload);
    }
  }, [data?.data, setState]);

  const { isLoading, mutate: createPrize } = useMutation(
    async (input) => await editPrize(id, input),
    {
      onSuccess(data) {
        toast.success(data?.message);
        navigation(-1);
      },
      onError(error) {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...state,
    };
    createPrize(payload);
  };

  const getContestList = (contests) => {
    if (!contests) return [];
    const list = contests.map((contest) => ({
      label: contest.title,
      value: contest._id,
    }));
    return list;
  };

  const filterContests = async (search) => {
    const data = await getContests({ search });
    return getContestList(data.data);
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterContests(inputValue));
      }, 1000);
    });

  const categories = [
    {
      label: "Winner",
      value: "Winner",
    },
    {
      label: "First Runner-Up",
      value: "First Runner-Up",
    },
    {
      label: "Second Runner-Up",
      value: "Second Runner-Up",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  if (fetching) return <p>Loading...</p>;

  return (
    <div>
      <DashBoardHeader headerTitle="Setup" />

      <InlineNavigation previousPage="Prizes" currentPage="Edit Prize" />
      <div className="edit__cate__holder">
        <h3 className="edit__cate__title">EDIT PRIZE</h3>
        <br />

        <>
          <form className="page-container" onSubmit={handleSubmit}>
            <div className="contest-dd">
              <AsyncSelectInput
                placeHolder="Select Contest"
                name="contest"
                value={state.contest}
                handleChange={onChangeByNameValue}
                options={getContestList(contests?.data?.data)}
                loadOptions={promiseOptions}
                isRequired={true}
                selectCustomClass="form-input"
                innerTextError=""
                labelText="Contest"
              />
            </div>
            <div className="description-text">
              <TextInput
                label="Prize Name"
                name="name"
                value={state.name}
                required={true}
                onChange={onChange}
              />
            </div>
            <div className="description-text">
              <TextArea
                cols="55"
                rows="3"
                label="Description"
                name="description"
                value={state.description}
                onChange={onChange}
              />
            </div>
            <SelectInput
              placeHolder="Select Contest"
              name="category"
              value={state.category}
              handleChange={onChangeByNameValue}
              options={categories}
              loadOptions={promiseOptions}
              isRequired={true}
              selectCustomClass="form-input"
              innerTextError=""
              labelText="Category"
            />

            <div className="save-btn">
              <Button isLoading={isLoading} className="saved-button">
                Save
              </Button>
            </div>
          </form>
        </>
      </div>
    </div>
  );
};

export default EditPrice;
