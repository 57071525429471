import React from "react";
import propTypes from "prop-types";
import "./textArea.scss";

const TextArea = ({
  rows = "5",
  cols = "60",
  label,
  className = "textarea",
  placeholder,
  ...rest
}) => {
  return (
    <div className="area-container">
      <p className="label">{label}</p>
      <textarea
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        className={className}
        {...rest}
      ></textarea>
    </div>
  );
};
TextArea.propTypes = {
  rows: propTypes.string,
  cols: propTypes.string,
  label: propTypes.string,
  className: propTypes.string,
  placeholder: propTypes.string,
};

export default TextArea;
