import React, { useEffect } from "react";
import "./edit-talent-category.scss";
import TextInput from "components/inputs/TextInput";
import toast from "react-hot-toast";
import Button from "components/button";
import DashBoardHeader from "components/headers/dashboard-header/DashBoardHeader";
import InlineNavigation from "components/inline-navigation/InlineNavigation";
import useInputChange from "hooks/useInputChange";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { editTalentCategories } from "services/talentCategories.service";
import useTalentCategory from "hooks/useTalentCategory";
import Skeleton from "react-loading-skeleton";

const EditTalentCategory = () => {
  const { id } = useParams();

  const { isLoading: fetching, data } = useTalentCategory(id);
  const { state, onChange, setState } = useInputChange(
    data?.data || {
      name: "",
      description: "",
      numberOfContestants: "",
    }
  );

  useEffect(() => {
    if (data?.data) {
      setState(data?.data);
    }
  }, [data?.data, setState]);

  const navigation = useNavigate();

  const { isLoading, mutate: createTalent } = useMutation(
    async (input) => await editTalentCategories(id, input),
    {
      onSuccess(data) {
        toast.success(data?.message);
        navigation(-1);
      },
      onError(error) {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...state,
      numberOfContestants: Number(state.numberOfContestants),
    };
    createTalent(payload);
  };
  return (
    <div>
      <DashBoardHeader headerTitle="Setup" />

      <InlineNavigation previousPage="Talent Categories" currentPage="Edit" />
      {fetching ? (
        <Skeleton width="100%" height={300} />
      ) : (
        <form className="view__modal__use" onSubmit={handleSubmit}>
          <div className="edit__cate__hold">
            <h3 className="edit__cate__tit">
              {id ? "EDIT" : "CREATE NEW"} TALENT
            </h3>
            <br />
            <div className="cat__name">
              <TextInput
                label="Category Name"
                placeholder="Enter Category Name"
                name="name"
                value={state.name}
                required={true}
                onChange={onChange}
              />
            </div>

            <div className="cat__name">
              <TextInput
                label="Number of contestants/category"
                placeholder="150"
                name="numberOfContestants"
                value={state.numberOfContestants}
                onChange={onChange}
                type="number"
              />
            </div>
            <div className="cat__name">
              <TextInput
                label="Description"
                placeholder="Description"
                name="description"
                value={state.description}
                onChange={onChange}
              />
            </div>

            <Button
              // className="update__bbtn"
              type="submit"
              isLoading={isLoading}
            >
              <span>Submit</span>
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditTalentCategory;
