import React from "react";
import "./modal.scss";
import { CgCloseO } from "react-icons/cg";
import useDisableBodyScroll from "utils/custom-hooks/useDisableBodyScroll";

const Modal = ({ children, showModal, showModalHandler }) => {
  useDisableBodyScroll(showModal);

  return (
    <div
      className={`modal modal__position-manager ${
        showModal ? "modal--open" : "modal--hide"
      }`}
    >
      <div className="modal__body modal__body--white modal__body--shadow">
        <div className="modal__close">
          <button
            type="button"
            className="button modal__button"
            onClick={showModalHandler}
          >
            <CgCloseO color="white" />
          </button>
        </div>
        <div className="modal__content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
