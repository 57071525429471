import Button from "components/button";
import * as ContentMgtURL from "constants/urls/ContentManagement";
import Modal from "components/modal/Modal";
import React, { useState } from "react";
import { RiEditLine } from "react-icons/ri";
import { RiDeleteBin5Line } from "react-icons/ri";
import toast from "react-hot-toast";
import "./setup-team.scss";
import { useNavigate } from "react-router-dom";
import useTeams from "hooks/useTeams";
import { useQueryClient, useMutation } from "react-query";
import { deleteTeam } from "services/team.service";
import Skeleton from "react-loading-skeleton";
import Jumbotron from "components/jumbotron/index";
import Image from "components/image/index";

const SetupTeam = () => {
  const [openModal3, setOpenModal3] = useState(false);

  const { isLoading, data } = useTeams();
  const [team, setTeam] = useState(null);

  const queryClient = useQueryClient();

  const { isLoading: deleting, mutate: deleteCategory } = useMutation(
    async (id) => await deleteTeam(id),
    {
      onSuccess(data) {
        toast.success(data?.message);
        setOpenModal3(false);
        queryClient.invalidateQueries("teams");
      },
      onError(error) {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const modalIsOpen3 = () => {
    setOpenModal3((prev) => !prev);
  };
  const modalIsClose3 = () => {
    setOpenModal3();
  };
  const confirmDelete = () => {
    deleteCategory(team._id);
  };

  const navigator = useNavigate();

  const navigatToCreateNewTeam = () => {
    navigator(
      `${ContentMgtURL.ADMIN_CONTENTMANGEMENT_INDEX_ROUTE}/create-new-team`
    );
  };

  const navigatToEditTeam = (id) => {
    navigator(
      `${ContentMgtURL.ADMIN_CONTENTMANGEMENT_INDEX_ROUTE}/teams/${id}`
    );
  };

  const handleDelete = (category) => {
    setTeam(category);
    setOpenModal3(true);
  };

  return (
    <div className="resulttable__wrapper">
      <div className="add-btn-wrapper">
        <Button onClick={navigatToCreateNewTeam}>
          <span>+ Add New</span>
        </Button>
      </div>

      {openModal3 && (
        <Modal showModal={modalIsOpen3} showModalHandler={modalIsClose3}>
          <div className="view__modal__user1">
            <div>
              <h3 className="sure__del">Are you sure?</h3>
              <div className="btns2">
                <Button className="c__btn" onClick={modalIsOpen3}>
                  <span>Cancel</span>
                </Button>
                <Button
                  className="c__btn__1"
                  onClick={confirmDelete}
                  isLoading={deleting}
                >
                  <span>Delete</span>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div className=" teams-wrapper">
        <table className="result__table__container2">
          <thead className="result__table__header">
            <tr>
              <th className="result__header__items" colSpan={2}>
                <p>Name</p>
              </th>
              <th className="result__header__items">
                <p>Designation</p>
              </th>
              <th className="result__header__items">
                <p>Action</p>
              </th>
            </tr>
          </thead>
          <tbody className="result__data__items__holder">
            {isLoading ? (
              <td colSpan={3}>
                <Skeleton count={3} />
              </td>
            ) : (
              <>
                {data?.data?.length < 1 ? (
                  <tr>
                    <td colSpan={3}>
                      <Jumbotron message="No Data" />
                    </td>
                  </tr>
                ) : (
                  <>
                    {data?.data?.map((item) => (
                      <tr key={item._id}>
                        <td className="result__data__itemss" colSpan={2}>
                          <div className="team-user">
                            <Image src={item.cover?.url} />
                            {item.name}
                          </div>
                        </td>
                        <td className="result__data__items">
                          {item.designation}
                        </td>
                        <td className="result__data__items1">
                          <div className="del__edi__holder">
                            <p className="edi">
                              <RiEditLine
                                onClick={() => navigatToEditTeam(item._id)}
                              />
                            </p>
                            <p className="dele">
                              <RiDeleteBin5Line
                                onClick={() => handleDelete(item)}
                              />
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SetupTeam;
