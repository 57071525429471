import React from "react";
import propTypes from "prop-types";
import { FiArrowUpRight } from "react-icons/fi";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BsHandThumbsDownFill } from "react-icons/bs";
import { BsHandThumbsUpFill } from "react-icons/bs";
import { MdAdd } from "react-icons/md";
import "./button.scss";

//Primary Button dynamically rendering icons with button
//@author Obicodeman
/***
@props {Text} btnText - Button Component's Action Text
***/
/***
 * @example JS - Usage
 *
 * <Button <hasIcon>" btnText="sign in"/>
 * */

const Button = ({
  btnText,
  className = "primary--button",
  clickHandler,
  hasIcon = false,
  rightArr = false,
  voter = false,
  voterfingUp = false,
  addIcon = false,
}) => {
  return (
    <div className="generic--button">
      <button type="button" className={className} onClick={clickHandler}>
        {addIcon && (
          <span className="add-icon">
            <MdAdd />
          </span>
        )}
        <span className="btn-text">{btnText}</span>
        {hasIcon && (
          <span>
            <FiArrowUpRight />
          </span>
        )}
        {rightArr && (
          <span>
            <AiOutlineArrowRight />
          </span>
        )}
        {voter && (
          <span className="voters-print">
            <BsHandThumbsDownFill />
          </span>
        )}
        {voterfingUp && (
          <span>
            <BsHandThumbsUpFill />
          </span>
        )}
      </button>
    </div>
  );
};

Button.propTypes = {
  btnText: propTypes.string.isRequired,
  hasIcon: propTypes.bool,
  rightArr: propTypes.bool,
  voter: propTypes.bool,
  voterfingUp: propTypes.bool,
  className: propTypes.string,
  clickHandler: propTypes.func,
};

export default Button;
