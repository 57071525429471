import {
  handleDeleteRequest,
  handleGetRequest,
  handlePostRequest,
  handlePuttRequest,
} from "./httpClient";

export const addTeam = async (input) => {
  return await handlePostRequest(`/teams`, input);
};

export const getTeams = async () => {
  return await handleGetRequest(`/teams`);
};

export const editTeam = async (id, input) => {
  return await handlePuttRequest(`/teams/${id}`, input);
};
export const deleteTeam = async (id) => {
  return await handleDeleteRequest(`/teams/${id}`);
};

export const getOneTeam = async (id) => {
  return await handleGetRequest(`/teams/${id}`);
};
