import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { ADMIN_CONTENTMANGEMENT_INDEX_ROUTE } from "constants/urls/ContentManagement";
import BrandLoader from "components/brand-loader/BrandLoader";

import AdminRoutes from "./content-management/AdminRoutes";
import {
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  RESET_PASSWORD_ROUTE,
} from "constants/urls/Landing";
import PrivateRoutes from "./PrivateRoute";

const AdminContentLayout = lazy(() =>
  import("layouts/content-management/AdminDashBoardLayout")
);

const Home = lazy(() => import("pages/content-management/admin/home/Home"));
const ResetPassword = lazy(() =>
  import("pages/content-management/admin/resetPassword/ResetPassword")
);
const ForgotPassword = lazy(() =>
  import("pages/content-management/admin/forgotPassword")
);
// const PageNotFound = lazy(() => import("pages/page-not-found/PageNotFound"));

const GlobalRoutes = () => {
  return (
    <>
      <Suspense fallback={<BrandLoader />}>
        <Routes>
          <Route path={HOME_ROUTE}>
            <Route index element={<Home />} />
          </Route>
          <Route path={RESET_PASSWORD_ROUTE}>
            <Route index element={<ResetPassword />} />
          </Route>
          <Route path={FORGOT_PASSWORD_ROUTE}>
            <Route index element={<ForgotPassword />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route
              path={ADMIN_CONTENTMANGEMENT_INDEX_ROUTE}
              element={<AdminContentLayout />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={`${ADMIN_CONTENTMANGEMENT_INDEX_ROUTE}/dashboard`}
                  />
                }
              />
              <Route
                path={`${ADMIN_CONTENTMANGEMENT_INDEX_ROUTE}/*`}
                element={<AdminRoutes />}
              />
            </Route>
          </Route>

          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Routes>
      </Suspense>
    </>
  );
};

export default GlobalRoutes;
