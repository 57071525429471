import { useState } from "react";
import { useQuery } from "react-query";
import useDebounce from "./useDebounceSearch";
import { getContests } from "services/contest.service";

export default function useContests(defaultQuery) {
  const [query, setQuery] = useState(
    defaultQuery || {
      page: 1,
      limit: 8,
      search: "",
    }
  );
  const debounceSearch = useDebounce(query.search, 1000);

  const { page } = query;
  const { isLoading, data, refetch } = useQuery(
    ["contests", page, debounceSearch],
    async () => await getContests(query)
  );

  const onQueryChange = (name, value) => {
    setQuery((q) => ({ ...q, [name]: value }));
  };

  const onFilter = (queryFilter) => {
    setQuery(queryFilter);
  };

  return { isLoading, data, onQueryChange, onFilter, refetch };
}
