import RoundUserAvatar from "components/round-user-avatar/RoundUserAvatar";
import React from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "./avatar-button.scss";

const AvatarButton = ({
  imgSrc,
  showAvatarDropdown,
  setShowAvatarDropdown,
}) => {
  return (
    <div className="avatar-button">
      <button
        type="button"
        className=" avatar-button__toggle"
        onClick={() =>
          setShowAvatarDropdown((showAvatarDropdown) => !showAvatarDropdown)
        }
      >
        <RoundUserAvatar imgSrc={imgSrc} />
        <span
          className={`avatar-button__icon ${
            showAvatarDropdown ? "icon--rotated" : "icon--unset-rotate"
          }`}
        >
          <MdOutlineKeyboardArrowDown />
        </span>
      </button>
    </div>
  );
};

export default AvatarButton;
