import { Navigate, Outlet, redirect } from "react-router-dom";
import { getAccessToken } from "utils/storage";
import { jwtDecode } from "jwt-decode";
import { UserType } from "constants/enums";
import { HOME_ROUTE } from "constants/urls/Landing";

const PrivateRoutes = () => {
  let accessToken = getAccessToken();

  // if there's no accestoken
  if (!accessToken) redirect(HOME_ROUTE);

  const decoded = jwtDecode(accessToken);

  // if it's not a valid token
  if (!decoded) redirect(HOME_ROUTE);

  // If user is not an admin
  if (decoded.type !== UserType.Admin) redirect(HOME_ROUTE);

  return decoded && decoded?.type === UserType.Admin ? (
    <Outlet />
  ) : (
    <Navigate to={HOME_ROUTE} />
  );
};

export default PrivateRoutes;
