import React, { useState } from "react";
// import SearchInput from "components/search-input/SearchInput";
import AvatarButton from "components/avatar-button/AvatarButton";
import AvatarDropdown from "components/avatar-dropdown/AvatarDropdown";
import "./dashboard-header.scss";
import useCurrentUser from "hooks/useCurrentUser";
import useProfile from "hooks/useProfile";

const DashBoardHeader = ({ headerTitle }) => {
  const [showAvatarDropdown, setShowAvatarDropdown] = useState(false);
  const currentUser = useCurrentUser();
  const { data } = useProfile(currentUser?.id);

  return (
    <header className="dashboard-header">
      <div className="dashboard-header__content">
        <h2 className="dashboard-header__heading fluid-type">{headerTitle}</h2>
        {/* <div className="dashboard-header__search">
          <SearchInput placeholder="search..." />
        </div> */}
        <p></p>
        <div className="dashboard-header__right-content">
          <div className="button__holder">
            <AvatarButton
              imgSrc={data?.data?.profilePicture?.url}
              setShowAvatarDropdown={setShowAvatarDropdown}
              showAvatarDropdown={showAvatarDropdown}
            />
            <AvatarDropdown
              imgSrc={data?.data?.profilePicture?.url}
              showDropdown={showAvatarDropdown}
              email={data?.data?.email}
              name={`${data?.data?.firstName} ${data?.data?.lastName}`}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default DashBoardHeader;
