"use client";

export const setCurrentUser = (user) => {
  try {
    if (user) {
      window.localStorage?.setItem("currentUser", JSON.stringify(user));
    }
  } catch (e) {
    // saving error
  }
};
export const getCurrentUser = () => {
  try {
    const value = localStorage?.getItem("currentUser");
    if (value !== null) {
      return JSON.parse(value);
    }

    return undefined;
  } catch (e) {
    // error reading value
  }
};

export const removeCurrentUser = () => {
  try {
    localStorage?.removeItem("currentUser");
  } catch (e) {
    // saving error
  }
};

export const setAccessToken = (token) => {
  try {
    if (token) window.localStorage?.setItem("accesstk", token);
  } catch (e) {
    // saving error
  }
};
export const getAccessToken = () => {
  try {
    const value = window.localStorage?.getItem("accesstk");
    if (value !== null) {
      return value;
    }

    return "";
  } catch (e) {
    // error reading value
  }
};
export const clearAccessToken = () => {
 
    window.localStorage?.removeItem("accesstk");
};

export const setRefreshToken = (token) => {
  try {
   
      if (token) window.localStorage?.setItem("refreshtk", token);

  } catch (e) {
    // saving error
  }
};
export const getRefreshToken = () => {
  try {
   
      const value = window.localStorage?.getItem("refreshtk");
      if (value !== null) {
        return value;
      }
    

    return "";
  } catch (e) {
    // error reading value
  }
};
export const clearRefreshToken = async () => {
 
    window.localStorage?.removeItem("refreshtk");
};

