import React from "react";
import "./broadcastCard.scss";
import { BroadcastChannel, BroadcastStatus } from "constants/enums";
import parse from "html-react-parser";

const BroadcastCard = ({ broadcast, onClick }) => {
  const handleClick = () => {
    onClick(broadcast);
  };
  return (
    <div className="card-holder" onClick={handleClick}>
      <div className="header-section">
        <p className="phase-title">
          {broadcast.status === BroadcastStatus.Draft && <span>Draft</span>}
          <span>{broadcast.title}</span>
        </p>
        <p>{new Date(broadcast.createdAt).toDateString()}</p>
      </div>
      <div className="description-section">
        <p>{parse(broadcast.content)}</p>
      </div>
      <div className="card-footer">
        <p>To: {broadcast.recipient}</p>
        <p>
          <ul>
            <li
              className={`${
                broadcast.channel.includes(BroadcastChannel.SMS) ? "active" : ""
              }`}
            >
              SMS
            </li>
            <li
              className={`${
                broadcast.channel?.includes(BroadcastChannel.Email)
                  ? "active"
                  : ""
              }`}
            >
              Email
            </li>
            <li
              className={`${
                broadcast.channel.includes(BroadcastChannel.Dashboard)
                  ? "active"
                  : ""
              }`}
            >
              Dashboard
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default BroadcastCard;
