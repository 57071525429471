import axios from "axios";
import { BASE_URL } from "../constants/environment";
import {
  clearAccessToken,
  clearRefreshToken,
  getAccessToken,
  // getRefreshToken,
  removeCurrentUser,
  // setAccessToken,
} from "../utils/storage";

const appAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": true,
    crossorigin: true,
  },
});

// Add a request interceptor
appAxios.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    if (token) {
      if (config.headers) config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return error;
  }
);

appAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    if (error.response.status === 401) {
      clearAccessToken();
      clearRefreshToken();
      removeCurrentUser();
      window.location = "/";
    }
    // const originalRequest = error.config;

    // if (
    //   error.response.status === 401 &&
    //   originalRequest.url === REFRESH_TOKEN_API
    // ) {
    //   history.push(LOGIN_ROUTE);
    //   return Promise.reject(error);
    // }
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;

    //   const { data } = await appAxios.get(REFRESH_TOKEN_API);
    //   const { token } = data;
    //   if (token) {
    //     localStorage.setItem("access_tk", token);
    //     originalRequest.headers["Authorization"] = `Bearer ${token}`;
    //     return appAxios(originalRequest);
    //   } else {
    //     localStorage.removeItem("access_tk");
    //     history.push(LOGIN_ROUTE);
    //   }
    // }

    return Promise.reject(error);
  }
);
export default appAxios;
