import {
  handleDeleteRequest,
  handleGetRequest,
  handlePatchRequest,
  handlePostRequest,
  handlePuttRequest,
} from "./httpClient";

export const getUserDetails = async (id) => {
  return await handleGetRequest(`/auth/users/${id}/profile`);
};

export const getContestantDetails = async (id) => {
  return await handleGetRequest(`/auth/users/${id}/contestant`);
};

export const deleteUser = async (id) => {
  return await handleDeleteRequest(`/auth/users/${id}`);
};

export const changeUserStatus = async (id, status) => {
  return await handlePuttRequest(`/auth/users/${id}/status`, { status });
};

export const getUserActivities = async (id, query) => {
  return await handleGetRequest(
    `/user-activities/users/${id}?page=${query.page}&limit=${query.limit}`
  );
};
export const getVoters = async (params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(`/auth/voters${queryString}`);
};

export const getAllContestants = async (params) => {
  const queryString = conststructQuery(params);
  return await handleGetRequest(`/auth/contestants${queryString}`);
};

export const updateUserProfile = async (data) => {
  return await handlePuttRequest(`/auth/users/profile`, data);
};

export const changePassword = async (data) => {
  return await handlePostRequest(`/auth/change-password`, data);
};
export const resetPassword = async (data) => {
  return await handlePostRequest(`/auth/reset-password`, data);
};

export const approveKyc = async (id, status) => {
  return await handlePatchRequest(`/auth/users/${id}/update-kyc-status`, {
    status,
  });
};
export const updateNotificationView = async () => {
  return await handlePatchRequest(`/auth/users/update-notification-view`, {});
};

export const unapproveKyc = async (id) => {
  return await handlePatchRequest(`/auth/users/${id}/un-approve-kyc`, {});
};

const conststructQuery = (params) => {
  const { page = 1, limit = 50 } = params;
  let query = `?page=${page}&limit=${limit}`;

  if (params.search) {
    query = query.concat(`&search=${params.search}`);
  }
  if (params.type) {
    query = query.concat(`&type=${params.type}`);
  }
  if (params.role) {
    query = query.concat(`&role=${params.role}`);
  }

  return query;
};
