import Button from "components/button";
import Modal from "components/modal/Modal";
import React, { useState } from "react";
import { RiEditLine } from "react-icons/ri";
import { RiDeleteBin5Line } from "react-icons/ri";
import toast from "react-hot-toast";
import * as ContentMgtURL from "constants/urls/ContentManagement";
import "./talent-categories.scss";
import { useNavigate } from "react-router-dom";
import useTalentCategories from "hooks/useTalentCategories";
import Skeleton from "react-loading-skeleton";
import Jumbotron from "components/jumbotron/index";
import { useMutation, useQueryClient } from "react-query";
import { deleteTalentCategory } from "services/talentCategories.service";

const TalentCategories = () => {
  const [openModal3, setOpenModal3] = useState(false);
  const { isLoading, data } = useTalentCategories();
  const [category, setCategory] = useState(null);

  const queryClient = useQueryClient();

  const { isLoading: deleting, mutate: deleteCategory } = useMutation(
    async (id) => await deleteTalentCategory(id),
    {
      onSuccess(data) {
        toast.success(data?.message);
        setOpenModal3(false);
        queryClient.invalidateQueries("talentCategories");
      },
      onError(error) {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const modalIsOpen3 = () => {
    setOpenModal3((prev) => !prev);
  };
  const modalIsClose3 = () => {
    setOpenModal3();
  };
  const confirmDelete = () => {
    deleteCategory(category._id);
  };

  const navigator = useNavigate();
  const navigatToCreateNewTalent = () => {
    navigator(
      `${ContentMgtURL.ADMIN_CONTENTMANGEMENT_INDEX_ROUTE}/create-new-talent`
    );
  };

  const navigatToEditCategory = (id) => {
    navigator(
      `${ContentMgtURL.ADMIN_CONTENTMANGEMENT_INDEX_ROUTE}/talent-categories/${id}`
    );
  };

  const handleDelete = (category) => {
    setCategory(category);
    setOpenModal3(true);
  };
  return (
    <div className="resulttable__wrapper">
      <div className="add-btn-wrapper">
        <Button onClick={navigatToCreateNewTalent}>
          <span>+ Add New</span>
        </Button>
      </div>

      {openModal3 && (
        <Modal showModal={modalIsOpen3} showModalHandler={modalIsClose3}>
          <div className="view__modal__user1">
            <div>
              <h3 className="sure__del">Are you sure?</h3>
              <div className="btns2">
                <Button className="c__btn" onClick={modalIsOpen3}>
                  <span>Cancel</span>
                </Button>
                <Button
                  className="c__btn__1"
                  btnText="DELETE"
                  onClick={confirmDelete}
                  isLoading={deleting}
                >
                  <span>Delete</span>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div className="">
        <div>
          <table className="result__table__container1">
            <thead className="result__table__header">
              <tr>
                <th className="result__header__items">
                  <p>Category Name</p>
                </th>
                <th className="result__header__items">
                  <p>Description</p>
                </th>
                <th className="result__header__items">
                  <p>Action</p>
                </th>
              </tr>
            </thead>

            <tbody className="result__data__items__holder">
              {isLoading ? (
                <td colSpan={3}>
                  <Skeleton count={3} />
                </td>
              ) : (
                <>
                  {data?.data.legnth < 1 ? (
                    <tr>
                      <td colSpan={3}>
                        <Jumbotron message="No Data" />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {data?.data?.map((item) => (
                        <tr key={item._id}>
                          <td className="result__data__itemss">{item.name}</td>
                          <td className="result__data__items">
                            {item.description}
                          </td>
                          <td className="result__data__items1">
                            <div className="del__edi__holder">
                              <p className="edi">
                                <RiEditLine
                                  onClick={() =>
                                    navigatToEditCategory(item._id)
                                  }
                                />
                              </p>
                              <p className="dele">
                                <RiDeleteBin5Line
                                  onClick={() => handleDelete(item)}
                                />
                              </p>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TalentCategories;
