import Logout from "components/button/logout-button/Logout";
import RoundUserAvatar from "components/round-user-avatar/RoundUserAvatar";
import React, { useState } from "react";
import { MdArrowForward } from "react-icons/md";

import { Link } from "react-router-dom";
import * as ContentMgtURL from "constants/urls/ContentManagement";
import "./avatar-dropdown.scss";

const AvatarDropdown = ({ imgSrc, name, email, showDropdown }) => {
  const [openProfile, setOpenProfile] = useState();

  const handleOpenCloseProfile = (e) => {
    openProfile(true);
    setOpenProfile(false);
  };

  return (
    <div
      className={`${
        showDropdown
          ? "avatar-dropdown visibile card"
          : "avatar-dropdown invisible card"
      }`}
    >
      <div className="avatar-dropdown__content card__content">
        <div className="view-avatar__details">
          <Link
            onClick={handleOpenCloseProfile}
            to={`${ContentMgtURL.ADMIN_CONTENTMANGEMENT_INDEX_ROUTE}/profile`}
            className="view-details__link"
          >
            <div className="avatar__holder">
              <RoundUserAvatar imgSrc={imgSrc} />
            </div>
            <div className="avatar__data">
              <span className="avatar__data-name">{name}</span>
              <span className="avatar__data-email">{email}</span>
            </div>
            <MdArrowForward />
          </Link>
        </div>
        <button className="logout-user__cta">
          <Logout />
        </button>
      </div>
    </div>
  );
};

export default AvatarDropdown;
