import React, { lazy } from "react";
import * as ContentMgtURL from "constants/urls/ContentManagement";
import { Routes, Route } from "react-router-dom";
import EditPrice from "pages/content-management/admin/setup-contest/edit-price/EditPrice";
import EditTalentCategory from "pages/content-management/admin/setup-contest/edit-talent-category/EditTalentCategory";
import TalentCategories from "pages/content-management/admin/setup-contest/components/TalentCategories";
import SetupTeam from "pages/content-management/admin/setup-contest/components/SetupTeam";
import Prizes from "pages/content-management/admin/setup-contest/components/Prizes";
import SetupTermsAndConditions from "pages/content-management/admin/setup-contest/components/SetupTermsAndConditions";
import AllBroadCasts from "pages/content-management/admin/broadcasts/components/allBroadcast/index";
import AllArchiveBroadCasts from "pages/content-management/admin/broadcasts/components/archiveBroadcast/index";
import AllDraftBroadCasts from "pages/content-management/admin/broadcasts/components/draftBroadcast/index";

const AdminDashboard = lazy(() =>
  import("pages/content-management/admin/dashboard/Dashboard")
);

const AddAdmin = lazy(() =>
  import("pages/content-management/admin/admin-user/add-admin/AddAdmin")
);

const ViewAdminDetails = lazy(() =>
  import(
    "pages/content-management/admin/admin-user/admin-details/ViewAdminDetails"
  )
);

const AdminViewAllContests = lazy(() =>
  import("pages/content-management/admin/contests/Contests")
);

const AdminViewAllContestants = lazy(() =>
  import("pages/content-management/admin/contestants/Contestants")
);

const ViewContestantsDetails = lazy(() =>
  import(
    "pages/content-management/admin/contestants/contestants-details/ViewContestantsDetails"
  )
);

const AdminViewAllBroadcasts = lazy(() =>
  import("pages/content-management/admin/broadcasts/BroadCasts")
);

const CreateBroadcast = lazy(() =>
  import(
    "pages/content-management/admin/broadcasts/create-broadcast/CreateBroadcast"
  )
);

const BroadcastCardUpdate = lazy(() =>
  import(
    "pages/content-management/admin/broadcasts/broadcast-cardupdate/BroadcastCardUpdate"
  )
);

const PublishedBroadcast = lazy(() =>
  import(
    "pages/content-management/admin/broadcasts/published/PublishedBroadcast"
  )
);

const ArchiveBroadcast = lazy(() =>
  import("pages/content-management/admin/broadcasts/archive/ArchiveBroadcast")
);

const EditArchive = lazy(() =>
  import(
    "pages/content-management/admin/broadcasts/archive/edit-archive/EditArchive"
  )
);

const DraftBroadcast = lazy(() =>
  import("pages/content-management/admin/broadcasts/draft/DraftBroadcast")
);

const AdminViewAllContestResult = lazy(() =>
  import("pages/content-management/admin/results/Results")
);

const AdminViewAllContestResultPhase = lazy(() =>
  import("pages/content-management/admin/results/resultphase/ResultsPhase")
);

const ContestsPhases = lazy(() =>
  import(
    "pages/content-management/admin/contests/view-contests/contests-phase/ContestsPhases"
  )
);

const ViewContestPhase = lazy(() =>
  import(
    "pages/content-management/admin/contests/view-contests/contests-phase/view-contest-phase1/ViewContestPhases"
  )
);

const ViewPhaseDetails = lazy(() =>
  import(
    "pages/content-management/admin/contests/view-contests/contests-phase/view-contest-phase1/viewface-details/ViewPhaseDetails"
  )
);

const EditPhase = lazy(() =>
  import(
    "pages/content-management/admin/contests/view-contests/contests-phase/edit-phase/EditPhase"
  )
);

const AdminViewAllContestVote = lazy(() =>
  import("pages/content-management/admin/results/resultphase/vote/Vote")
);

const AdminViewAllContestVideos = lazy(() =>
  import("pages/content-management/admin/videos/Videos")
);

const AdminViewAllContestVideoPhase = lazy(() =>
  import("pages/content-management/admin/videos/VideoPhase")
);

const ViewContest = lazy(() =>
  import("pages/content-management/admin/contests/view-contests/ViewContest")
);

const EditContests = lazy(() =>
  import("pages/content-management/admin/contests/edit-contests/EditContests")
);

const CreatePhases = lazy(() =>
  import(
    "pages/content-management/admin/contests/view-contests/contests-phase/create-phases/CreatePhases"
  )
);

const CreateContest = lazy(() =>
  import("pages/content-management/admin/contests/create-contest/CreateContest")
);

const AdminViewAllVideos = lazy(() =>
  import("pages/content-management/admin/videos/ViewAllVideos")
);

const AdminViewContestPayments = lazy(() =>
  import("pages/content-management/admin/payments/Payments")
);

const ViewPaymentDetails = lazy(() =>
  import(
    "pages/content-management/admin/payments/view-payment-details/ViewPaymentDetails"
  )
);

const SetupContest = lazy(() =>
  import("pages/content-management/admin/setup-contest/SetupContest")
);

const CreateNewConSetupContest = lazy(() =>
  import(
    "pages/content-management/admin/setup-contest/create-new/CreateNewConSetupContest"
  )
);

const CreateNewTalentConSetup = lazy(() =>
  import(
    "pages/content-management/admin/setup-contest/create-new-talent/CreateNewTalentConSetup"
  )
);

const CreateNewTeam = lazy(() =>
  import(
    "pages/content-management/admin/setup-contest/create-new-team/CreateNewTeam"
  )
);

const EditSetup = lazy(() =>
  import("pages/content-management/admin/setup-contest/edit-setup/EditSetup")
);

const AdminRolesAndPermission = lazy(() =>
  import("pages/content-management/admin/rolespermission/RolesAndPermissions")
);

const EditAdminRoles = lazy(() =>
  import(
    "pages/content-management/admin/rolespermission/edit-roles/EditAdminRoles"
  )
);

const AdminViewAllVoters = lazy(() =>
  import("pages/content-management/admin/voters/Voters")
);

const ViewAllAdmins = lazy(() =>
  import("pages/content-management/admin/admin-user/AdminUsers")
);

const Profile = lazy(() =>
  import("pages/content-management/admin/profile/Profile")
);
const ContestantContest = lazy(() =>
  import("pages/content-management/admin/contestants/contestant-contests")
);

const ContestantApplication = lazy(() =>
  import("pages/content-management/admin/contestants/application-details")
);

const AdminRoutes = () => {
  return (
    <Routes>
      <Route
        path={ContentMgtURL.ADMIN_DASHBOARD_ROUTE}
        element={<AdminDashboard />}
      />
      <Route
        path={ContentMgtURL.ADMIN_ADD_ADMIN_ROUTE}
        element={<AddAdmin />}
      />

      <Route
        path={ContentMgtURL.ADMIN_VIEW_ADMIN_DETAILS_ROUTE}
        element={<ViewAdminDetails />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_ALL_CONTEST_ROUTE}
        element={<AdminViewAllContests />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_ALL_CONTESTANTS_ROUTE}
        element={<AdminViewAllContestants />}
      />

      <Route
        path={ContentMgtURL.ADMIN_VIEW_CONTESTANTS_DETAILS_ROUTE}
        element={<ViewContestantsDetails />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_CONTESTS_ROUTE}
        element={<ViewContest />}
      />
      <Route
        path={ContentMgtURL.ADMIN_EDIT_CONTEST_ROUTE}
        element={<EditContests />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_CONTESTS__CREATE_PHASE_ROUTE}
        element={<CreatePhases />}
      />
      <Route
        path={ContentMgtURL.ADMIN_CREATE_CONTEST_ROUTE}
        element={<CreateContest />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_PHASE_RESULTS_ROUTE}
        element={<AdminViewAllContestResult />}
      />
      <Route
        path={ContentMgtURL.ADMIN_CONTEST_PHASES__ROUTE}
        element={<ContestsPhases />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_CONTEST_PHASE__ROUTE}
        element={<ViewContestPhase />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_CONTEST_PHASE__DETAILS_ROUTE}
        element={<ViewPhaseDetails />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_PHASE_VOTE_ROUTE}
        element={<AdminViewAllContestVote />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_RESULTS_PHASE_ROUTE}
        element={<AdminViewAllContestResultPhase />}
      />
      <Route
        path={ContentMgtURL.ADMIN_EDIT_PHASE__ROUTE}
        element={<EditPhase />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_ALL_CONTEST_VIDEO_ROUTE}
        element={<AdminViewAllContestVideos />}
      />

      <Route
        path={ContentMgtURL.ADMIN_VIEW_ALL_CONTEST_VIDEOPHASE_ROUTE}
        element={<AdminViewAllContestVideoPhase />}
      />

      <Route
        path={ContentMgtURL.ADMIN_VIEW_ALL_VIDEOS_ROUTE}
        element={<AdminViewAllVideos />}
      />

      <Route
        path={ContentMgtURL.ADMIN_VIEW_ALL_CONTEST_PAYMENTS_ROUTE}
        element={<AdminViewContestPayments />}
      />

      <Route
        path={ContentMgtURL.ADMIN_VIEW_ALL_CONTEST_PAYMENTS_DETAILS_ROUTE}
        element={<ViewPaymentDetails />}
      />
      <Route
        path={ContentMgtURL.ADMIN_SETUP_CONTEST_ROUTE}
        element={<SetupContest />}
      >
        <Route index element={<TalentCategories />} />
        <Route path={`talent-categories`} element={<TalentCategories />} />
        <Route path={`teams`} element={<SetupTeam />} />
        <Route path={`prizes`} element={<Prizes />} />
        <Route
          path={`terms-and-condition`}
          element={<SetupTermsAndConditions />}
        />
      </Route>

      <Route
        path={ContentMgtURL.ADMIN_CREATE_SETUP_CONTEST_ROUTE}
        element={<CreateNewConSetupContest />}
      />

      <Route
        path={ContentMgtURL.ADMIN_CREATE_TALENT_CONTEST_ROUTE}
        element={<CreateNewTalentConSetup />}
      />

      <Route
        path={ContentMgtURL.ADMIN_CREATE_TEAM_CONTEST_ROUTE}
        element={<CreateNewTeam />}
      />

      <Route
        path={ContentMgtURL.ADMIN_EDIT_SETUP_CONTEST_ROUTE}
        element={<EditSetup />}
      />

      <Route
        path={ContentMgtURL.ADMIN_EDIT_PRICE_CONTEST_ROUTE}
        element={<EditPrice />}
      />

      <Route
        path={ContentMgtURL.ADMIN_EDIT_TALENT_CATEGORY_CONTEST_ROUTE}
        element={<EditTalentCategory />}
      />

      <Route
        path={ContentMgtURL.ADMIN_ROLES_PERMISSION_CONTEST_ROUTE}
        element={<AdminRolesAndPermission />}
      />

      <Route
        path={ContentMgtURL.ADMIN_EDIT_ROLES_CONTEST_ROUTE}
        element={<EditAdminRoles />}
      />

      <Route
        path={ContentMgtURL.ADMIN_VIEW_ALL_VOTERS_ROUTE}
        element={<AdminViewAllVoters />}
      />

      <Route
        path={ContentMgtURL.VIEW_ALL_ADMIN_USERS_ROUTE}
        element={<ViewAllAdmins />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_ALL_BROADCAST_ROUTE}
        element={<AdminViewAllBroadcasts />}
      />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_ALL_BROADCAST_ROUTE}
        element={<AdminViewAllBroadcasts />}
      >
        <Route index element={<AllBroadCasts />} />
        <Route path={`published`} element={<AllBroadCasts />} />
        <Route path={`archive`} element={<AllArchiveBroadCasts />} />
        <Route path={`draft`} element={<AllDraftBroadCasts />} />
      </Route>
      <Route
        path={ContentMgtURL.ADMIN_CREATE_BROADCAST_ROUTE}
        element={<CreateBroadcast />}
      />
      <Route
        path={ContentMgtURL.ADMIN_BROADCAST_CARDUPDATE_ROUTE}
        element={<BroadcastCardUpdate />}
      />
      <Route
        path={ContentMgtURL.ADMIN_BROADCAST_PUBLISHED_ROUTE}
        element={<PublishedBroadcast />}
      />
      <Route
        path={ContentMgtURL.ADMIN_BROADCAST_ARCHIVE_ROUTE}
        element={<ArchiveBroadcast />}
      />
      <Route
        path={ContentMgtURL.ADMIN_BROADCAST_EDIT_ARCHIVE_ROUTE}
        element={<EditArchive />}
      />
      <Route
        path={ContentMgtURL.ADMIN_BROADCAST_DRAFT_ROUTE}
        element={<DraftBroadcast />}
      />
      <Route path={ContentMgtURL.PROFILE_INDEX_ROUTE} element={<Profile />} />
      <Route
        path={ContentMgtURL.ADMIN_VIEW_CONTESTANTS_CONTEST_ROUTE}
        element={<ContestantContest />}
      />
      <Route
        path={ContentMgtURL.CONTESTANTS_APPLICATIONS_ROUTE}
        element={<ContestantApplication />}
      />
    </Routes>
  );
};

export default AdminRoutes;
